export default {
  "themes": [
    "108blue",
    "agent",
    "betcc",
    "brown",
    "darkBlue",
    "default",
    "jbbGreen",
    "luck",
    "mango",
    "mango10",
    "mango12",
    "mango13",
    "mango15",
    "mango16",
    "mango17",
    "mango2",
    "mango20",
    "mango21",
    "mango24H5",
    "mango24Web",
    "mango25",
    "mango26",
    "mango27",
    "mango28",
    "mango29",
    "mango3",
    "mango30",
    "mango31",
    "mango32",
    "mango4",
    "mango5",
    "mango6",
    "mango7",
    "mango8",
    "orange",
    "pink",
    "purple"
  ],
  "108blue": {
    "--cms-primary-color": "#005f74",
    "--cms-primary-color-transition": "rgba(0,95,116,0.05)",
    "--cms-primary-color-disabled": "rgba(0,95,116,0.4)",
    "--cms-primary-nav-color-hover": "#fff100",
    "--cms-primary-color-hover": "#fff100",
    "--cms-primary-second-color": "#10e56f",
    "--cms-primary-background-color": "#fff",
    "--cms-header-top-background-color": "linear-gradient(#d3fffe,#19b4b8)",
    "--cms-header-nav-background-color": "linear-gradient(#19b4b8,#d3fffe)",
    "--cms-default-btn-color": "linear-gradient(#dbffe9,#effff8,#f8fffc,#eefff9,#fff)",
    "--cms-active-btn-color": "linear-gradient(90deg,#00336a,#00ffde)",
    "--cms-default-btn-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67),inset 0px 1px 7px 0px #fff,inset 0px -3px 4px 0px rgba(175,194,230,0.16)",
    "--cms-active-btn-shadow-color": "inset 0px 0px 3px 0px hsla(0,0%,100%,0.6),0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-primary-light-color": "rgba(250,157,16,0.7)",
    "--cms-primary-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-primary-active-border-color": "#abffd0",
    "--cms-login-register-bg-color": "rgba(0,95,116,0.85)",
    "--cms-primary-icon-bg": "#e1bd61",
    "--cms-primary-icon-bg-gradient": "linear-gradient(180deg,#ffc164,var(--cms-primary-color))",
    "--cms-primary-icon-bg-gradient-right": "linear-gradient(270deg,#81daff,var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-second-color) 1%,var(--cms-primary-color))",
    "--cms-loading-background-color": "rgba(0,0,0,0.5)",
    "--cms-loading-block-background-color": "rgba(0,0,0,0.3)",
    "--cms-loading-block-h5-background-color": "none",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#fff",
    "--cms-user-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-background-card-color": "#fff",
    "--cms-background-card--avtive-color": "rgba(0,95,116,0.1)",
    "--cms-user-menu-active-color": "rgba(0,95,116,0.1)",
    "--cms-user-info-color": "linear-gradient(180deg,#e1fff1,#fafafa)",
    "--cms-user-info-btn-shadow-color": "4px 4px 6px 1px rgba(26,118,85,0.2)",
    "--cms-login-bg-color": "rgba(0,153,68,0.85)",
    "--cms-login-btn-color": "#fff100",
    "--cms-banner-slide-color": "#a3d9bb",
    "--cms-title-color": "#000",
    "--cms-sub-title-color": "#666",
    "--cms-primary-background-row-color": "#f2f2f2",
    "--cms-text-tip-color": "#bb3537",
    "--cms-game-name-bg-color": "linear-gradient(180deg,rgba(250,157,16,0),#1e85ff)",
    "--cms-game-nav-bg-color": "linear-gradient(180deg,#ffc164,var(--cms-primary-color))",
    "--cms-game-nav-text-color": "#fa9d10",
    "--cms-game-left-popup-bg": "rgba(0,153,68,0.6)",
    "--cms-game-left-popup-bg2": "#333431",
    "--cms-game-nav-text-active-color": "#fff",
    "--cms-game-scence3-bg": "#e3ebf8",
    "--cms-withdrawal-wallet-bg-color": "linear-gradient(270deg,#ffc164,var(--cms-primary-color))",
    "--cms-live-content-primary-background-color": "linear-gradient(180deg,#effff5,#effff8 24%,#f8fffc 55%,#eefff9 85%,#fff)",
    "--cms-live-content-primary-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-live-content-primary-border-color": "1px solid rgba(0,153,68,0.4)",
    "--cms-live-content-footer-primary-background-color": "linear-gradient(270deg,#097434,#effff8 19%,#139658 38%,#67c899 66%,#eefff9 85%,#087a36)"
  },
  "agent": {
    "--cms-mango-agent-background-color": "#0e131b",
    "--cms-mango-agent-background-color2": "#1f2531",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-header-background-color": "#181f2b",
    "--cms-mango-agent-pop-header-background-color": "#2c3544",
    "--cms-mango-agent-pop-background-color": "#1e2531",
    "--cms-mango-agent-border-color": "#59657c",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#0e131b",
    "--cms-mango-agent-text-green-color": "#0e131b",
    "--cms-mango-text-color": "#fff",
    "--cms-mango-text-color2": "#59657c",
    "--cms-mango-text-color3": "#99abd0",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-loading-text-color": "#0e131b",
    "--cms-mango-tips-background": "#0e131b",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)",
    "--sp-primary-color": "#0e131b",
    "--sp-primary-light-color": "#fff"
  },
  "betcc": {
    "--cms-primary-color": "#6cc26d",
    "--cms-mango-primary-color": "#6cc26d",
    "--cms-mango-primary-opacity-color": "rgba(108,194,109,0.3)",
    "--cms-mango-primary-dark-color": "#4b9552",
    "--cms-mango-header-login-btn-color": "linear-gradient(#738595,#304657)",
    "--cms-mango-header-login-btn-hover-color": "linear-gradient(#3a495c,#192737)",
    "--cms-mango-header-register-btn-color": "linear-gradient(#6cc26d,#4b9552)",
    "--cms-mango-header-register-btn-hover-color": "linear-gradient(#1f6d3d,#14552f)",
    "--cms-mango-background-color": "#243241",
    "--cms-mango-background-color-end": "#0f171f",
    "--cms-mango-background-color2": "#121932",
    "--cms-mango-background-color2-end": "#02081a",
    "--cms-mango-background-color3": "#101934",
    "--cms-mango-background-color4": "#354f60",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#243241",
    "--cms-mango-header-background-color": "#2a3941",
    "--cms-mango-personal-background-color": "linear-gradient(#243241,#5a6579,#243241)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#223141,#151c34)",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.1)",
    "--cms-mango-border-color": "#4c5551",
    "--cms-mango-border-light-color": "#b1bad3",
    "--cms-mango-border-dark-color": "#2d445b",
    "--cms-mango-text-color": "#aeb6cd",
    "--cms-mango-text-color2": "#5a6579",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#a5b0c9",
    "--cms-mango-text-color5": "#d4dbdb",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00b5b5",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "brown": {
    "--cms-primary-color": "#6c4119",
    "--cms-primary-color-transition": "rgba(108,65,25,0.05)",
    "--cms-primary-color-disabled": "rgba(108,65,25,0.4)",
    "--cms-primary-nav-color-hover": "#fff100",
    "--cms-primary-color-hover": "#fff100",
    "--cms-primary-second-color": "#10e56f",
    "--cms-primary-background-color": "#fff",
    "--cms-default-btn-color": "linear-gradient(#dbffe9,#effff8,#f8fffc,#eefff9,#fff)",
    "--cms-active-btn-color": "linear-gradient(#73f9ae,#35cf79,var(--cms-primary-color))",
    "--cms-default-btn-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67),inset 0px 1px 7px 0px #fff,inset 0px -3px 4px 0px rgba(175,194,230,0.16)",
    "--cms-active-btn-shadow-color": "inset 0px 0px 3px 0px hsla(0,0%,100%,0.6),0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-primary-light-color": "rgba(108,65,25,0.7)",
    "--cms-primary-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-primary-active-border-color": "#abffd0",
    "--cms-login-register-bg-color": "rgba(108,65,25,0.85)",
    "--cms-primary-icon-bg": "#e1bd61",
    "--cms-primary-icon-bg-gradient": "linear-gradient(180deg,#f4a1a0,var(--cms-primary-color))",
    "--cms-primary-icon-bg-gradient-right": "linear-gradient(270deg,#f4a1a0,var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-second-color) 1%,var(--cms-primary-color))",
    "--cms-loading-background-color": "rgba(0,0,0,0.5)",
    "--cms-loading-block-background-color": "rgba(0,0,0,0.3)",
    "--cms-loading-block-h5-background-color": "none",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#fff",
    "--cms-user-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-background-card-color": "#fff",
    "--cms-background-card--avtive-color": "rgba(0,153,68,0.1)",
    "--cms-user-menu-active-color": "rgba(0,153,68,0.1)",
    "--cms-game-scence3-bg": "#e3ebf8",
    "--cms-user-info-color": "linear-gradient(180deg,#e1fff1,#fafafa)",
    "--cms-user-info-btn-shadow-color": "4px 4px 6px 1px rgba(26,118,85,0.2)",
    "--cms-login-bg-color": "rgba(0,153,68,0.85)",
    "--cms-login-btn-color": "#fff100",
    "--cms-banner-slide-color": "#a3d9bb",
    "--cms-title-color": "#000",
    "--cms-sub-title-color": "#666",
    "--cms-primary-background-row-color": "#f2f2f2",
    "--cms-text-tip-color": "#bb3537",
    "--cms-game-name-bg-color": "linear-gradient(180deg,rgba(108,65,25,0),#1e85ff)",
    "--cms-game-nav-bg-color": "linear-gradient(180deg,#b5763b,#6c4119)",
    "--cms-game-nav-text-color": "#6c4119",
    "--cms-game-left-popup-bg": "rgba(108,65,25,0.6)",
    "--cms-game-left-popup-bg2": "rgba(108,65,25,0.6)",
    "--cms-game-nav-text-active-color": "#fff",
    "--cms-withdrawal-wallet-bg-color": "linear-gradient(180deg,#b5763b,#6c4119)",
    "--cms-live-content-primary-background-color": "linear-gradient(180deg,#effff5,#effff8 24%,#f8fffc 55%,#eefff9 85%,#fff)",
    "--cms-live-content-primary-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-live-content-primary-border-color": "1px solid rgba(0,153,68,0.4)",
    "--cms-live-content-footer-primary-background-color": "linear-gradient(270deg,#097434,#effff8 19%,#139658 38%,#67c899 66%,#eefff9 85%,#087a36)"
  },
  "darkBlue": {
    "--cms-primary-color": "#071267",
    "--cms-primary-color-transition": "rgba(7,18,103,0.05)",
    "--cms-primary-color-disabled": "rgba(7,18,103,0.4)",
    "--cms-primary-nav-color-hover": "#fff100",
    "--cms-primary-color-hover": "#fff100",
    "--cms-primary-second-color": "#10e56f",
    "--cms-primary-background-color": "#fff",
    "--cms-default-btn-color": "linear-gradient(#dbffe9,#effff8,#f8fffc,#eefff9,#fff)",
    "--cms-active-btn-color": "linear-gradient(#73f9ae,#35cf79,var(--cms-primary-color))",
    "--cms-default-btn-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67),inset 0px 1px 7px 0px #fff,inset 0px -3px 4px 0px rgba(175,194,230,0.16)",
    "--cms-active-btn-shadow-color": "inset 0px 0px 3px 0px hsla(0,0%,100%,0.6),0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-primary-light-color": "rgba(31,0,46,0.7)",
    "--cms-primary-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-primary-active-border-color": "#abffd0",
    "--cms-login-register-bg-color": "rgba(31,0,46,0.85)",
    "--cms-primary-icon-bg": "#e1bd61",
    "--cms-primary-icon-bg-gradient": "linear-gradient(180deg,#622c7c,var(--cms-primary-color))",
    "--cms-primary-icon-bg-gradient-right": "linear-gradient(270deg,#81daff,var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-second-color) 1%,var(--cms-primary-color))",
    "--cms-loading-background-color": "rgba(0,0,0,0.5)",
    "--cms-loading-block-background-color": "rgba(0,0,0,0.3)",
    "--cms-loading-block-h5-background-color": "none",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#fff",
    "--cms-user-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-background-card-color": "#fff",
    "--cms-background-card--avtive-color": "rgba(7,18,103,0.1)",
    "--cms-user-menu-active-color": "rgba(7,18,103,0.1)",
    "--cms-user-info-color": "linear-gradient(180deg,#e1fff1,#fafafa)",
    "--cms-user-info-btn-shadow-color": "4px 4px 6px 1px rgba(26,118,85,0.2)",
    "--cms-login-bg-color": "rgba(0,153,68,0.85)",
    "--cms-login-btn-color": "#fff100",
    "--cms-banner-slide-color": "#a3d9bb",
    "--cms-title-color": "#000",
    "--cms-sub-title-color": "#666",
    "--cms-primary-background-row-color": "#f2f2f2",
    "--cms-text-tip-color": "#bb3537",
    "--cms-game-name-bg-color": "linear-gradient(180deg,rgba(250,157,16,0),#1e85ff)",
    "--cms-game-nav-bg-color": "linear-gradient(180deg,#3242be,#071267)",
    "--cms-game-nav-text-color": "#071267",
    "--cms-game-left-popup-bg": "rgba(7,18,103,0.6)",
    "--cms-game-left-popup-bg2": "rgba(7,18,103,0.6)",
    "--cms-game-nav-text-active-color": "#fff",
    "--cms-game-scence3-bg": "#e3ebf8",
    "--cms-withdrawal-wallet-bg-color": "linear-gradient(180deg,#3242be,#071267)",
    "--cms-live-content-primary-background-color": "linear-gradient(180deg,#effff5,#effff8 24%,#f8fffc 55%,#eefff9 85%,#fff)",
    "--cms-live-content-primary-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-live-content-primary-border-color": "1px solid rgba(0,153,68,0.4)",
    "--cms-live-content-footer-primary-background-color": "linear-gradient(270deg,#097434,#effff8 19%,#139658 38%,#67c899 66%,#eefff9 85%,#087a36)"
  },
  "default": {
    "--cms-primary-color": "#4c9dec",
    "--cms-primary-color-transition": "rgba(76,157,236,0.05)",
    "--cms-primary-color-disabled": "rgba(76,157,236,0.4)",
    "--cms-primary-nav-color-hover": "#fff100",
    "--cms-primary-color-hover": "#fff100",
    "--cms-primary-second-color": "#10e56f",
    "--cms-primary-background-color": "#fff",
    "--cms-default-btn-color": "linear-gradient(180deg,#effeff,#e4f4ff 24%,#f8fdff 55%,#effaff 85%,#fff)",
    "--cms-default-btn-shadow-color": "0px 4px 8px 0px rgba(194,210,222,0.67),inset 0px 1px 7px 0px #fff,inset 0px -3px 4px 0px rgba(175,194,230,0.16)",
    "--cms-active-btn-color": "linear-gradient(180deg,#7dd4fd,#4c9dec)",
    "--cms-active-btn-shadow-color": "inset 0px 0px 3px 0px hsla(0,0%,100%,0.6),0px 4px 8px 0px rgba(118,206,244,0.67)",
    "--cms-primary-light-color": "rgba(118,206,244,0.7)",
    "--cms-primary-title-bg-color": "linear-gradient(270deg,#7dd4fd,var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-second-color) 1%,var(--cms-primary-color))",
    "--cms-login-register-bg-color": "rgba(76,157,236,0.85)",
    "--cms-primary-icon-bg": "#e1bd61",
    "--cms-primary-icon-bg-gradient": "linear-gradient(180deg,#81daff,var(--cms-primary-color))",
    "--cms-primary-icon-bg-gradient-right": "linear-gradient(270deg,#81daff,var(--cms-primary-color))",
    "--cms-loading-background-color": "rgba(0,0,0,0.5)",
    "--cms-loading-block-background-color": "rgba(0,0,0,0.3)",
    "--cms-loading-block-h5-background-color": "none",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#fff",
    "--cms-user-title-bg-color": "linear-gradient(270deg,#7dd4fd,var(--cms-primary-color))",
    "--cms-background-card-color": "#fff",
    "--cms-background-card--avtive-color": "rgba(76,157,236,0.1)",
    "--cms-user-menu-active-color": "rgba(76,157,236,0.1)",
    "--cms-game-scence3-bg": "#e3ebf8",
    "--cms-user-info-color": "linear-gradient(180deg,#e1efff,#fafafa)",
    "--cms-user-info-btn-shadow-color": "4px 4px 6px 0px rgba(26,90,118,0.2)",
    "--cms-login-bg-color": "rgba(0,153,68,0.85)",
    "--cms-login-btn-color": "#fff100",
    "--cms-banner-slide-color": "#92bdff",
    "--cms-title-color": "#000",
    "--cms-sub-title-color": "#666",
    "--cms-primary-background-row-color": "#f2f2f2",
    "--cms-text-tip-color": "#bb3537",
    "--cms-user-level-background-color": "rgba(76,157,236,0.3)",
    "--cms-primary-active-border-color": "linear-gradient(180deg,#7dd4fd,#4c9dec)",
    "--cms-live-content-primary-background-color": "linear-gradient(180deg,#effaff,#eff9ff 24%,#f8feff 55%,#eefbff 85%,#fff)",
    "--cms-live-content-primary-shadow-color": "0px 4px 8px 0px rgba(194,212,222,0.67)",
    "--cms-live-content-primary-border-color": "1px solid rgba(9,147,246,0.4)",
    "--cms-live-content-footer-primary-background-color": "linear-gradient(270deg,#0e5fd9,#caecff 19%,#387ce2 38%,#6dd7f9 66%,#75cdff 84%,#0b92bd)",
    "--cms-game-name-bg-color": "linear-gradient(180deg,rgba(0,117,255,0),#1e85ff)",
    "--cms-game-nav-bg-color": "linear-gradient(180deg,#82daff,var(--cms-primary-color))",
    "--cms-game-nav-text-color": "var(--cms-primary-color)",
    "--cms-game-nav-text-active-color": "#fff",
    "--cms-game-left-popup-bg": "rgba(0,153,68,0.6)",
    "--cms-game-left-popup-bg2": "rgba(76,157,236,0.6)",
    "--cms-withdrawal-wallet-bg-color": "linear-gradient(270deg,#82daff,var(--cms-primary-color))"
  },
  "jbbGreen": {
    "--cms-primary-color": "#094",
    "--cms-primary-color-transition": "rgba(0,153,68,0.05)",
    "--cms-primary-color-disabled": "rgba(0,153,68,0.4)",
    "--cms-primary-nav-color-hover": "#fff100",
    "--cms-primary-color-hover": "#fff100",
    "--cms-primary-second-color": "#10e56f",
    "--cms-primary-background-color": "#fff",
    "--cms-default-btn-color": "linear-gradient(#dbffe9,#effff8,#f8fffc,#eefff9,#fff)",
    "--cms-active-btn-color": "linear-gradient(#73f9ae,#35cf79,var(--cms-primary-color))",
    "--cms-default-btn-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67),inset 0px 1px 7px 0px #fff,inset 0px -3px 4px 0px rgba(175,194,230,0.16)",
    "--cms-active-btn-shadow-color": "inset 0px 0px 3px 0px hsla(0,0%,100%,0.6),0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-primary-light-color": "rgba(0,153,68,0.7)",
    "--cms-primary-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-primary-active-border-color": "#abffd0",
    "--cms-login-register-bg-color": "rgba(0,153,68,0.85)",
    "--cms-primary-icon-bg": "#ff9b20",
    "--cms-primary-icon-bg-gradient": "linear-gradient(180deg,#00c979,var(--cms-primary-color))",
    "--cms-primary-icon-bg-gradient-right": "linear-gradient(270deg,#81daff,var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-second-color) 1%,var(--cms-primary-color))",
    "--cms-loading-background-color": "rgba(0,0,0,0.5)",
    "--cms-loading-block-background-color": "rgba(0,0,0,0.3)",
    "--cms-loading-block-h5-background-color": "none",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#fff",
    "--cms-user-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-background-card-color": "#fff",
    "--cms-background-card--avtive-color": "rgba(0,153,68,0.1)",
    "--cms-user-menu-active-color": "rgba(0,153,68,0.1)",
    "--cms-user-info-color": "linear-gradient(180deg,#e1fff1,#fafafa)",
    "--cms-user-info-btn-shadow-color": "4px 4px 6px 1px rgba(26,118,85,0.2)",
    "--cms-login-bg-color": "rgba(0,153,68,0.85)",
    "--cms-login-btn-color": "#fff100",
    "--cms-banner-slide-color": "#a3d9bb",
    "--cms-title-color": "#000",
    "--cms-sub-title-color": "#666",
    "--cms-primary-background-row-color": "#f2f2f2",
    "--cms-text-tip-color": "#bb3537",
    "--cms-user-level-background-color": "rgba(0,153,68,0.3)",
    "--cms-game-name-bg-color": "linear-gradient(180deg,rgba(0,117,255,0),#1e85ff)",
    "--cms-game-nav-bg-color": "linear-gradient(180deg,#00c979,var(--cms-primary-color))",
    "--cms-game-nav-text-color": "#094",
    "--cms-game-nav-text-active-color": "#fff",
    "--cms-game-left-popup-bg": "rgba(0,153,68,0.6)",
    "--cms-game-left-popup-bg2": "#333431",
    "--cms-game-scence3-bg": "#e3ebf8",
    "--cms-withdrawal-wallet-bg-color": "linear-gradient(270deg,#00c979,var(--cms-primary-color))",
    "--cms-live-content-primary-background-color": "linear-gradient(180deg,#effff5,#effff8 24%,#f8fffc 55%,#eefff9 85%,#fff)",
    "--cms-live-content-primary-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-live-content-primary-border-color": "1px solid rgba(0,153,68,0.4)",
    "--cms-live-content-footer-primary-background-color": "linear-gradient(270deg,#097434,#effff8 19%,#139658 38%,#67c899 66%,#eefff9 85%,#087a36)"
  },
  "luck": {
    "--cms-primary-color": "#00ae78",
    "--cms-mango-primary-color": "#00ae78",
    "--cms-mango-primary-opacity-color": "rgba(0,174,120,0.3)",
    "--cms-mango-primary-dark-color": "#4b9552",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#f5f5f5",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#3ee7d0,#346e46)",
    "--cms-mango-user-progress-background-color": "var(--cms-mango-text-color4)",
    "--cms-mango-user-vip-background-color": "#fff",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00ae78",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango": {
    "--cms-primary-color": "#1dd0c3",
    "--cms-mango-primary-color": "#1dd0c3",
    "--cms-mango-primary-opacity-color": "rgba(29,208,195,0.3)",
    "--cms-mango-primary-dark-color": "#2b9287",
    "--cms-mango-header-login-btn-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-login-btn-hover-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-register-btn-color": "#dd234b",
    "--cms-mango-header-register-btn-hover-color": "#dd234b",
    "--cms-mango-background-color": "#0d131c",
    "--cms-mango-background-color2": "#16202d",
    "--cms-mango-background-color3": "#1c2532",
    "--cms-mango-background-color4": "#2a3546",
    "--cms-mango-background-color5": "#99abd0",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#1b3558,#182535)",
    "--cms-mango-pop-background-color": "#111923",
    "--cms-mango-header-background-color": "#1c2532",
    "--cms-mango-personal-background-color": "linear-gradient(#171f2f,#22448b,#161b25)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#1d5ca7,#262626)",
    "--cms-mango-progress-background-color": "#284676",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.46)",
    "--cms-mango-border-color": "#5c677a",
    "--cms-mango-border-light-color": "#f3f4f6",
    "--cms-mango-border-dark-color": "#202a39",
    "--cms-mango-text-color": "#66727d",
    "--cms-mango-text-color2": "#565a61",
    "--cms-mango-text-color3": "#93acd3",
    "--cms-mango-text-color4": "#55657e",
    "--cms-mango-text-color5": "#999",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#fce908",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#dd234b",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "linear-gradient(180deg,#284676 10%,#37424c 40%)",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,#00016b,var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#db384e",
    "--cms-mango-tips-btn-background": "#fadc4b",
    "--cms-mango-tips-btn-text": "#595e63"
  },
  "mango10": {
    "--cms-primary-color": "#8a4ed3",
    "--cms-mango-primary-color": "#8a4ed3",
    "--cms-mango-primary-opacity-color": "rgba(138,78,211,0.3)",
    "--cms-mango-primary-dark-color": "#633797",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#fff",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#8a4ed3,#6f3fab)",
    "--cms-mango-user-progress-background-color": "var(--cms-mango-text-color4)",
    "--cms-mango-user-vip-background-color": "#fff",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,#f5f5f5,var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#8a4ed3",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango12": {
    "--cms-primary-color": "#00bee1",
    "--cms-mango-primary-color": "#00bee1",
    "--cms-mango-primary-opacity-color": "rgba(0,190,225,0.3)",
    "--cms-mango-primary-dark-color": "#01798f",
    "--cms-primary-background-color": "#f4f2fb",
    "--cms-mango-background-color": "#f4f2fb",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#00bee1",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#07c8ec,#01798f)",
    "--cms-mango-user-progress-background-color": "var(--cms-mango-text-color4)",
    "--cms-mango-user-vip-background-color": "#fff",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#7e6294",
    "--cms-mango-text-color5": "#7e6294",
    "--cms-mango-text-color6": "#7e6294",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00bee1",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff"
  },
  "mango13": {
    "--cms-primary-color": "#e5a749",
    "--cms-mango-primary-color": "#e5a749",
    "--cms-mango-primary-color-end": "#2c3a43",
    "--cms-mango-primary-opacity-color": "rgba(29,208,195,0.3)",
    "--cms-primary-background-color": "linear-gradient(270deg,#1c252d,#37424c)",
    "--cms-mango-primary-dark-color": "#21464f",
    "--cms-mango-primary-dark-color2": "#182028",
    "--cms-mango-header-login-btn-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-login-btn-hover-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-register-btn-color": "#dd234b",
    "--cms-mango-header-register-btn-hover-color": "#dd234b",
    "--cms-mango-background-color": "#00211b",
    "--cms-mango-background-color-end": "#1c252d",
    "--cms-mango-background-color2": "#273038",
    "--cms-mango-background-color2-end": "#182028",
    "--cms-mango-background-color3": "#0a1916",
    "--cms-mango-background-color4": "#21464f",
    "--cms-mango-background-color5": "#21464f",
    "--cms-mango-background-color6": "#21464f",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#1b3558,#182535)",
    "--cms-mango-pop-background-color": "#00211b",
    "--cms-mango-header-background-color": "#1c2532",
    "--cms-mango-personal-background-color": "linear-gradient(#171f2f,#22448b,#161b25)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#1d5ca7,#262626)",
    "--cms-mango-progress-background-color": "#284676",
    "--cms-mango-game-top-background-color": "#0e161e",
    "--cms-mango-header-box-shadow-color": "0 2px 10px 0 rgba(0,0,0,0.1)",
    "--cms-mango-tabbar-box-shadow-color": "0 0 22px 20px rgba(0,0,0,0.46)",
    "--cms-mango-border-color": "#3e4952",
    "--cms-mango-border-light-color": "#f3f4f6",
    "--cms-mango-border-dark-color": "#3e4952",
    "--cms-mango-border-dark-bold-color": "#1b242d",
    "--cms-mango-text-color": "#98a7b5",
    "--cms-mango-text-color2": "#999a9a",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#98a7b5",
    "--cms-mango-text-color5": "#999",
    "--cms-mango-text-color6": "#dfe1e5",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#fdea60",
    "--cms-mango-tip-dark-yellow-color": "#f29c38",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#bb3537",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#1776f1",
    "--cms-mango-game-rule-background-color": "linear-gradient(180deg,#284676 10%,#37424c 40%)",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(360deg,#1a2b32,#2bc299)",
    "--cms-mango-agent-gradient-bg-color": "radial-gradient(100% 200% at 0% 0%,#35444e,#19242d)",
    "--cms-mango-agent-gradient-tab-color": "linear-gradient(180deg,#656e78,#1c1e22)",
    "--cms-mango-agent-gradient-tab-bg-color": "linear-gradient(180deg,#2d3035,#1c1e22)",
    "--cms-mango-agent-background-color": "#35444e",
    "--cms-mango-agent-background-color2": "#19242d",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#314352",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-mango-agent-icon-color1": "#59ffc2",
    "--cms-mango-agent-icon-color2": "#4551ff",
    "--cms-loading-text-color": "#fff"
  },
  "mango15": {
    "--cms-primary-color": "#fa9d10",
    "--cms-mango-primary-color": "#fa9d10",
    "--cms-mango-primary-opacity-color": "rgba(108,194,109,0.3)",
    "--cms-mango-primary-dark-color": "#e1a91d",
    "--cms-mango-header-login-btn-color": "linear-gradient(#738595,#304657)",
    "--cms-mango-header-login-btn-hover-color": "linear-gradient(#3a495c,#192737)",
    "--cms-mango-header-register-btn-color": "linear-gradient(#6cc26d,#4b9552)",
    "--cms-mango-header-register-btn-hover-color": "linear-gradient(#1f6d3d,#14552f)",
    "--cms-mango-background-color": "#1e1e1e",
    "--cms-mango-background-color-end": "#151414",
    "--cms-mango-background-color2": "#121932",
    "--cms-mango-background-color2-end": "#02081a",
    "--cms-mango-background-color3": "#101934",
    "--cms-mango-background-color4": "#354f60",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#25293b",
    "--cms-mango-header-background-color": "#2a3941",
    "--cms-mango-personal-background-color": "linear-gradient(#243241,#5a6579,#243241)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#223141,#151c34)",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.1)",
    "--cms-mango-border-color": "#4c5551",
    "--cms-mango-border-light-color": "#b1bad3",
    "--cms-mango-border-dark-color": "#2d445b",
    "--cms-mango-text-color": "#aeb6cd",
    "--cms-mango-text-color2": "#5a6579",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#848aa1",
    "--cms-mango-text-color5": "#848aa1",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00b5b5",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango16": {
    "--cms-primary-color": "#fddb2c",
    "--cms-mango-primary-color": "#fddb2c",
    "--cms-mango-primary-opacity-color": "rgba(108,194,109,0.3)",
    "--cms-mango-primary-dark-color": "#c5a916",
    "--cms-mango-header-login-btn-color": "linear-gradient(#738595,#304657)",
    "--cms-mango-header-login-btn-hover-color": "linear-gradient(#3a495c,#192737)",
    "--cms-mango-header-register-btn-color": "linear-gradient(#6cc26d,#4b9552)",
    "--cms-mango-header-register-btn-hover-color": "linear-gradient(#1f6d3d,#14552f)",
    "--cms-mango-background-color": "#1d1d1d",
    "--cms-mango-background-color-end": "#151414",
    "--cms-mango-background-color2": "#121932",
    "--cms-mango-background-color2-end": "#02081a",
    "--cms-mango-background-color3": "#262828",
    "--cms-mango-background-color4": "#354f60",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#25293b",
    "--cms-mango-header-background-color": "#2a3941",
    "--cms-mango-personal-background-color": "linear-gradient(#243241,#5a6579,#243241)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#223141,#151c34)",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.1)",
    "--cms-mango-border-color": "#4c5551",
    "--cms-mango-border-light-color": "#b1bad3",
    "--cms-mango-border-dark-color": "#2d445b",
    "--cms-mango-text-color": "#aeb6cd",
    "--cms-mango-text-color2": "#5a6579",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#848aa1",
    "--cms-mango-text-color5": "#848aa1",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00b5b5",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango17": {
    "--cms-primary-color": "#2cb34a",
    "--cms-mango-primary-color": "#2cb34a",
    "--cms-mango-primary-opacity-color": "rgba(0,174,120,0.3)",
    "--cms-mango-primary-dark-color": "#04801f",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#f5f5f5",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#2cb34a,#346e46)",
    "--cms-mango-user-progress-background-color": "var(--cms-mango-text-color4)",
    "--cms-mango-user-vip-background-color": "#fff",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#2b2b2b",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#2cb34a",
    "--cms-mango-agent-text-green-color": "#2cb34a",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#2cb34a",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango2": {
    "--cms-primary-color": "#2ec59c",
    "--cms-mango-primary-color": "#2ec59c",
    "--cms-mango-primary-color-end": "#2c3a43",
    "--cms-mango-primary-opacity-color": "rgba(29,208,195,0.3)",
    "--cms-primary-background-color": "linear-gradient(270deg,#1c252d,#37424c)",
    "--cms-mango-primary-dark-color": "#21464f",
    "--cms-mango-primary-dark-color2": "#182028",
    "--cms-mango-header-login-btn-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-login-btn-hover-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-register-btn-color": "#dd234b",
    "--cms-mango-header-register-btn-hover-color": "#dd234b",
    "--cms-mango-background-color": "#37424c",
    "--cms-mango-background-color-end": "#1c252d",
    "--cms-mango-background-color2": "#273038",
    "--cms-mango-background-color2-end": "#182028",
    "--cms-mango-background-color3": "#38444d",
    "--cms-mango-background-color4": "#21464f",
    "--cms-mango-background-color5": "#21464f",
    "--cms-mango-background-color6": "#21464f",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#1b3558,#182535)",
    "--cms-mango-pop-background-color": "linear-gradient(#273038,#182028)",
    "--cms-mango-header-background-color": "#1c2532",
    "--cms-mango-personal-background-color": "linear-gradient(#171f2f,#22448b,#161b25)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#1d5ca7,#262626)",
    "--cms-mango-progress-background-color": "#284676",
    "--cms-mango-game-top-background-color": "#0e161e",
    "--cms-mango-header-box-shadow-color": "0 2px 10px 0 rgba(0,0,0,0.1)",
    "--cms-mango-tabbar-box-shadow-color": "0 0 22px 20px rgba(0,0,0,0.46)",
    "--cms-mango-border-color": "#3e4952",
    "--cms-mango-border-light-color": "#f3f4f6",
    "--cms-mango-border-dark-color": "#3e4952",
    "--cms-mango-border-dark-bold-color": "#1b242d",
    "--cms-mango-text-color": "#98a7b5",
    "--cms-mango-text-color2": "#999a9a",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#98a7b5",
    "--cms-mango-text-color5": "#999",
    "--cms-mango-text-color6": "#dfe1e5",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#fdea60",
    "--cms-mango-tip-dark-yellow-color": "#f29c38",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#bb3537",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#1776f1",
    "--cms-mango-game-rule-background-color": "linear-gradient(180deg,#284676 10%,#37424c 40%)",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,#00016b,var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(360deg,#1a2b32,#2bc299)",
    "--cms-mango-agent-gradient-bg-color": "radial-gradient(100% 200% at 0% 0%,#35444e,#19242d)",
    "--cms-mango-agent-gradient-tab-color": "linear-gradient(180deg,#656e78,#1c1e22)",
    "--cms-mango-agent-gradient-tab-bg-color": "linear-gradient(180deg,#2d3035,#1c1e22)",
    "--cms-mango-agent-background-color": "#35444e",
    "--cms-mango-agent-background-color2": "#19242d",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#314352",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-mango-agent-icon-color1": "#59ffc2",
    "--cms-mango-agent-icon-color2": "#4551ff",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#2ec59c",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango20": {
    "--cms-primary-color": "#ffb800",
    "--cms-mango-primary-color": "#ffb800",
    "--cms-mango-primary-opacity-color": "rgba(255,184,0,0.3)",
    "--cms-mango-primary-dark-color": "#b28102",
    "--cms-mango-header-login-btn-color": "linear-gradient(#738595,#304657)",
    "--cms-mango-header-login-btn-hover-color": "linear-gradient(#3a495c,#192737)",
    "--cms-mango-header-register-btn-color": "linear-gradient(#6cc26d,#4b9552)",
    "--cms-mango-header-register-btn-hover-color": "linear-gradient(#1f6d3d,#14552f)",
    "--cms-mango-background-color": "#090b18",
    "--cms-mango-background-color-end": "#050710",
    "--cms-mango-background-color2": "#222536",
    "--cms-mango-background-color2-end": "#141623",
    "--cms-mango-background-color3": "#222536",
    "--cms-mango-background-color4": "#373b54",
    "--cms-mango-background-color5": "#373b54",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#25293b",
    "--cms-mango-header-background-color": "#2a3941",
    "--cms-mango-personal-background-color": "linear-gradient(#243241,#5a6579,#243241)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#223141,#151c34)",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.1)",
    "--cms-mango-border-color": "#4c5551",
    "--cms-mango-border-light-color": "#b1bad3",
    "--cms-mango-border-dark-color": "#2d445b",
    "--cms-mango-text-color": "#aeb6cd",
    "--cms-mango-text-color2": "#5a6579",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#848aa1",
    "--cms-mango-text-color5": "#848aa1",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00b5b5",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango21": {
    "--cms-primary-color": "#eab1b1",
    "--cms-mango-primary-color": "#eab1b1",
    "--cms-mango-primary-opacity-color": "rgba(234,177,177,0.3)",
    "--cms-mango-primary-dark-color": "#eab1b1",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#f5f5f5",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#fff",
    "--cms-mango-background-color4": "#fff",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#edd0d0,#eab1b1)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#edd0d0,#eab1b1)",
    "--cms-mango-user-progress-background-color": "var(--cms-mango-text-color4)",
    "--cms-mango-user-vip-background-color": "#fff",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#eab1b1",
    "--cms-mango-tip-blue-color": "#eab1b1",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#eab1b1",
    "--cms-mango-agent-text-green-color": "#eab1b1",
    "--cms-loading-text-color": "#eab1b1",
    "--cms-mango-tips-background": "#eab1b1",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)",
    "--sp-primary-color": "#eab1b1",
    "--sp-primary-light-color": "rgba(234,177,177,0.3)"
  },
  "mango24H5": {
    "--cms-primary-color": "#148763",
    "--cms-mango-primary-color": "#148763",
    "--cms-mango-primary-opacity-color": "rgba(142,109,195,0.3)",
    "--cms-mango-primary-dark-color": "#0c5740",
    "--cms-mango-header-login-btn-color": "#148763",
    "--cms-mango-header-register-btn-color": "#ffa200",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#f5f5f5",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-backgrond-other-color": "#0c5740",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#3ee7d0,#346e46)",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00ae78",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango24Web": {
    "--cms-primary-color": "#148763",
    "--cms-mango-primary-color": "#148763",
    "--cms-mango-primary-opacity-color": "rgba(29,208,195,0.3)",
    "--cms-mango-primary-dark-color": "#2b9287",
    "--cms-mango-header-login-btn-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-login-btn-hover-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-register-btn-color": "#dd234b",
    "--cms-mango-header-register-btn-hover-color": "#dd234b",
    "--cms-mango-background-color": "#148763",
    "--cms-mango-background-color2": "#0c5740",
    "--cms-mango-background-color3": "#0c5740",
    "--cms-mango-background-color4": "#148763",
    "--cms-mango-background-color5": "#99abd0",
    "--cms-mango-mini-game-background-color": "linear-gradient(#148763,#0c5740)",
    "--cms-mango-pop-background-color": "#0a3f2f",
    "--cms-mango-header-background-color": "#0c5740",
    "--cms-mango-personal-background-color": "linear-gradient(#148763,#0c5740)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#148763,#0c5740)",
    "--cms-mango-progress-background-color": "hsla(0,0%,100%,0.2)",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.46)",
    "--cms-mango-border-color": "hsla(0,0%,100%,0.3)",
    "--cms-mango-border-light-color": "#f3f4f6",
    "--cms-mango-border-dark-color": "#202a39",
    "--cms-mango-text-color": "#fff",
    "--cms-mango-text-color2": "#fff",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "hsla(0,0%,100%,0.8)",
    "--cms-mango-text-color5": "#999",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#fce908",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#dd234b",
    "--cms-mango-tip-green-color": "#ffa200",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "linear-gradient(180deg,#284676 10%,#37424c 40%)",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#db384e",
    "--cms-mango-tips-btn-background": "#fadc4b",
    "--cms-mango-tips-btn-text": "#595e63"
  },
  "mango25": {
    "--cms-primary-color": "#348568",
    "--cms-mango-primary-color": "#348568",
    "--cms-mango-primary-color-end": "#16362c",
    "--cms-mango-primary-opacity-color": "rgba(97,193,158,0.3)",
    "--cms-primary-background-color": "linear-gradient(270deg,#1c252d,#37424c)",
    "--cms-mango-primary-dark-color": "#21464f",
    "--cms-mango-primary-dark-color2": "#182028",
    "--cms-mango-header-login-btn-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-login-btn-hover-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-register-btn-color": "#dd234b",
    "--cms-mango-header-register-btn-hover-color": "#dd234b",
    "--cms-mango-background-color": "#2d353d",
    "--cms-mango-background-color-end": "#1b2128",
    "--cms-mango-background-color2": "#3a444c",
    "--cms-mango-background-color2-end": "#242c33",
    "--cms-mango-background-color3": "#252b33",
    "--cms-mango-background-color4": "#21464f",
    "--cms-mango-background-color5": "#21464f",
    "--cms-mango-background-color6": "#21464f",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#1b3558,#182535)",
    "--cms-mango-pop-background-color": "linear-gradient(#273038,#182028)",
    "--cms-mango-header-background-color": "#1c2532",
    "--cms-mango-personal-background-color": "linear-gradient(#171f2f,#22448b,#161b25)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#1d5ca7,#262626)",
    "--cms-mango-progress-background-color": "#284676",
    "--cms-mango-user-background-color": "#293037",
    "--cms-mango-game-top-background-color": "#0e161e",
    "--cms-mango-header-box-shadow-color": "0 2px 10px 0 rgba(0,0,0,0.1)",
    "--cms-mango-tabbar-box-shadow-color": "0 0 22px 20px rgba(0,0,0,0.46)",
    "--cms-mango-border-color": "#3e4952",
    "--cms-mango-border-light-color": "#f3f4f6",
    "--cms-mango-border-dark-color": "#3e4952",
    "--cms-mango-border-dark-bold-color": "#1b242d",
    "--cms-mango-text-color": "#adb6c4",
    "--cms-mango-text-color2": "#999a9a",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#98a7b5",
    "--cms-mango-text-color5": "#999",
    "--cms-mango-text-color6": "#dfe1e5",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#fdea60",
    "--cms-mango-tip-dark-yellow-color": "#f29c38",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#bb3537",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#1776f1",
    "--cms-mango-game-rule-background-color": "linear-gradient(180deg,#284676 10%,#37424c 40%)",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(360deg,#1a2b32,#2bc299)",
    "--cms-mango-agent-gradient-bg-color": "radial-gradient(100% 200% at 0% 0%,#35444e,#19242d)",
    "--cms-mango-agent-gradient-tab-color": "linear-gradient(180deg,#656e78,#1c1e22)",
    "--cms-mango-agent-gradient-tab-bg-color": "linear-gradient(180deg,#2d3035,#1c1e22)",
    "--cms-mango-agent-background-color": "#35444e",
    "--cms-mango-agent-background-color2": "#19242d",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#314352",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-mango-agent-icon-color1": "#59ffc2",
    "--cms-mango-agent-icon-color2": "#4551ff",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#2ec59c",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango26": {
    "--cms-primary-color": "#d80000",
    "--cms-mango-primary-color": "#d80000",
    "--cms-mango-primary-color-end": "#550100",
    "--cms-mango-primary-opacity-color": "rgba(216,0,0,0.3)",
    "--cms-primary-background-color": "linear-gradient(270deg,#550100,#d80000)",
    "--cms-mango-primary-dark-color": "#550100",
    "--cms-mango-primary-dark-color2": "#d80000",
    "--cms-mango-header-login-btn-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-login-btn-hover-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-register-btn-color": "#dd234b",
    "--cms-mango-header-register-btn-hover-color": "#dd234b",
    "--cms-mango-background-color": "#550100",
    "--cms-mango-background-color-end": "#550100",
    "--cms-mango-background-color2": "#950101",
    "--cms-mango-background-color2-end": "#7e0000",
    "--cms-mango-background-color3": "#720000",
    "--cms-mango-background-color4": "#720000",
    "--cms-mango-background-color5": "#d80000",
    "--cms-mango-background-color6": "#d80000",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#1b3558,#182535)",
    "--cms-mango-pop-background-color": "linear-gradient(#950101,#550100)",
    "--cms-mango-header-background-color": "#1c2532",
    "--cms-mango-personal-background-color": "linear-gradient(#171f2f,#22448b,#161b25)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#1d5ca7,#dcdfe4)",
    "--cms-mango-progress-background-color": "#284676",
    "--cms-mango-game-top-background-color": "#0e161e",
    "--cms-mango-header-box-shadow-color": "0 2px 10px 0 rgba(0,0,0,0.1)",
    "--cms-mango-tabbar-box-shadow-color": "0 0 22px 20px rgba(0,0,0,0.46)",
    "--cms-mango-border-color": "#dcdfe4",
    "--cms-mango-border-light-color": "#f3f4f6",
    "--cms-mango-border-dark-color": "#fff",
    "--cms-mango-border-dark-bold-color": "#550100",
    "--cms-mango-text-color": "#fff",
    "--cms-mango-text-color2": "#999a9a",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#dcdfe4",
    "--cms-mango-text-color5": "#999",
    "--cms-mango-text-color6": "#dfe1e5",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#fdea60",
    "--cms-mango-tip-dark-yellow-color": "#f29c38",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#1776f1",
    "--cms-mango-game-rule-background-color": "linear-gradient(180deg,#284676 10%,#37424c 40%)",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#f80e0d,#950101)",
    "--cms-mango-agent-gradient-bg-color": "radial-gradient(100% 200% at 0% 0%,#35444e,#19242d)",
    "--cms-mango-agent-gradient-tab-color": "linear-gradient(180deg,#ff4343,#f80e0d)",
    "--cms-mango-agent-gradient-tab-bg-color": "linear-gradient(180deg,#f80e0d,#950101)",
    "--cms-mango-agent-background-color": "#35444e",
    "--cms-mango-agent-background-color2": "#19242d",
    "--cms-mango-agent-background-color3": "#dcdfe4",
    "--cms-mango-agent-background-color4": "#314352",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-mango-agent-icon-color1": "#59ffc2",
    "--cms-mango-agent-icon-color2": "#4551ff",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#2ec59c",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango27": {
    "--cms-primary-color": "#7dd354",
    "--cms-mango-primary-color": "#7dd354",
    "--cms-mango-primary-color-end": "#027c00",
    "--cms-mango-primary-opacity-color": "rgba(125,211,84,0.3)",
    "--cms-primary-background-color": "linear-gradient(270deg,#027c00,#7dd354)",
    "--cms-mango-primary-dark-color": "#027c00",
    "--cms-mango-primary-dark-color2": "#7dd354",
    "--cms-mango-header-login-btn-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-login-btn-hover-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-register-btn-color": "#dd234b",
    "--cms-mango-header-register-btn-hover-color": "#dd234b",
    "--cms-mango-background-color": "#1d1d1d",
    "--cms-mango-background-color-end": "#1d1d1d",
    "--cms-mango-background-color2": "#000",
    "--cms-mango-background-color2-end": "#000",
    "--cms-mango-background-color3": "#111",
    "--cms-mango-background-color4": "#111",
    "--cms-mango-background-color5": "#7dd354",
    "--cms-mango-background-color6": "#7dd354",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#1b3558,#182535)",
    "--cms-mango-pop-background-color": "#000",
    "--cms-mango-header-background-color": "#1c2532",
    "--cms-mango-personal-background-color": "linear-gradient(#171f2f,#22448b,#161b25)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#1d5ca7,#dcdfe4)",
    "--cms-mango-progress-background-color": "#284676",
    "--cms-mango-game-top-background-color": "#0e161e",
    "--cms-mango-header-box-shadow-color": "0 2px 10px 0 rgba(0,0,0,0.1)",
    "--cms-mango-tabbar-box-shadow-color": "0 0 22px 20px rgba(0,0,0,0.46)",
    "--cms-mango-border-color": "#dcdfe4",
    "--cms-mango-border-light-color": "#f3f4f6",
    "--cms-mango-border-dark-color": "#fff",
    "--cms-mango-border-dark-bold-color": "#027c00",
    "--cms-mango-text-color": "#fff",
    "--cms-mango-text-color2": "#999a9a",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#dcdfe4",
    "--cms-mango-text-color5": "#999",
    "--cms-mango-text-color6": "#dfe1e5",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#fdea60",
    "--cms-mango-tip-dark-yellow-color": "#f29c38",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#f5f5f5",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#1776f1",
    "--cms-mango-game-rule-background-color": "linear-gradient(180deg,#284676 10%,#37424c 40%)",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#f80e0d,#950101)",
    "--cms-mango-agent-gradient-bg-color": "radial-gradient(100% 200% at 0% 0%,#35444e,#19242d)",
    "--cms-mango-agent-gradient-tab-color": "linear-gradient(180deg,#ff4343,#f80e0d)",
    "--cms-mango-agent-gradient-tab-bg-color": "linear-gradient(180deg,#f80e0d,#950101)",
    "--cms-mango-agent-background-color": "#35444e",
    "--cms-mango-agent-background-color2": "#19242d",
    "--cms-mango-agent-background-color3": "#dcdfe4",
    "--cms-mango-agent-background-color4": "#314352",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-mango-agent-icon-color1": "#59ffc2",
    "--cms-mango-agent-icon-color2": "#4551ff",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#2ec59c",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango28": {
    "--cms-primary-color": "#62a5ff",
    "--cms-mango-primary-color": "#479afe",
    "--cms-mango-primary-opacity-color": "rgba(71,154,254,0.3)",
    "--cms-mango-primary-dark-color": "#195fb4",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#fff",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#aecafb,#6e9df8)",
    "--cms-mango-user-progress-background-color": "var(--cms-mango-text-color4)",
    "--cms-mango-user-vip-background-color": "#fff",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-color9": "#636164",
    "--cms-mango-text-color9-h5": "#007afe",
    "--cms-mango-text-color10": "#000",
    "--cms-mango-background-color6": "#edf1f4",
    "--cms-mango-close-color": "#636164",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#479afe",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#479afe",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango29": {
    "--cms-primary-color": "#ff3c8a",
    "--cms-mango-primary-color": "#ff3c8a",
    "--cms-mango-primary-opacity-color": "rgba(255,60,138,0.3)",
    "--cms-mango-primary-dark-color": "#d83174",
    "--cms-mango-header-login-btn-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-login-btn-hover-color": "var(--cms-mango-primary-color)",
    "--cms-mango-header-register-btn-color": "#dd234b",
    "--cms-mango-header-register-btn-hover-color": "#dd234b",
    "--cms-primary-background-color": "#0c1b40",
    "--cms-mango-background-color": "#0d131c",
    "--cms-mango-background-color2": "#16202d",
    "--cms-mango-background-color3": "#1c2532",
    "--cms-mango-background-color4": "#2a3546",
    "--cms-mango-background-color5": "#99abd0",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#1b3558,#182535)",
    "--cms-mango-pop-background-color": "#111923",
    "--cms-mango-header-background-color": "#1c2532",
    "--cms-mango-personal-background-color": "linear-gradient(#171f2f,#22448b,#161b25)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#1d5ca7,#262626)",
    "--cms-mango-progress-background-color": "#284676",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.46)",
    "--cms-mango-border-color": "#5c677a",
    "--cms-mango-border-light-color": "#f3f4f6",
    "--cms-mango-border-dark-color": "#202a39",
    "--cms-mango-text-color": "#aeb6cd",
    "--cms-mango-text-color2": "#565a61",
    "--cms-mango-text-color3": "#93acd3",
    "--cms-mango-text-color4": "#55657e",
    "--cms-mango-text-color5": "#999",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-stroke-color": "#fff",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#fce908",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#dd234b",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-text-color9": "#fff",
    "--cms-mango-text-color9-h5": "#fff",
    "--cms-mango-text-color10": "#00d0f4",
    "--cms-mango-background-color6": "#0b1730",
    "--cms-mango-close-color": "#adadc2",
    "--cms-mango-game-rule-background-color": "linear-gradient(180deg,#284676 10%,#37424c 40%)",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#db384e",
    "--cms-mango-tips-btn-background": "#fadc4b",
    "--cms-mango-tips-btn-text": "#595e63"
  },
  "mango3": {
    "--cms-primary-color": "#9492ff",
    "--cms-mango-primary-color": "#9492ff",
    "--cms-mango-primary-opacity-color": "rgba(142,109,195,0.3)",
    "--cms-mango-primary-dark-color": "#261878",
    "--cms-mango-header-login-btn-color": "#24b0ca",
    "--cms-mango-header-register-btn-color": "#8a01da",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#f5f5f5",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-backgrond-other-color": "#261878",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#3ee7d0,#346e46)",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,#f5f5f5,var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00ae78",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango30": {
    "--cms-primary-color": "#ffef4c",
    "--cms-mango-primary-color": "#ffef4c",
    "--cms-mango-primary-opacity-color": "rgba(255,239,76,0.3)",
    "--cms-mango-primary-dark-color": "#9a8f1a",
    "--cms-primary-background-color": "#0b0c07",
    "--cms-mango-background-color": "#0b0c07",
    "--cms-mango-background-color2": "#303440",
    "--cms-mango-background-color3": "#000",
    "--cms-mango-background-color4": "#303440",
    "--cms-mango-background-color5": "#494c5b",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#1d1d1d",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#0b0c07",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#3ee7d0,#346e46)",
    "--cms-mango-user-progress-background-color": "var(--cms-mango-text-color4)",
    "--cms-mango-user-vip-background-color": "#fff",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#0b0c07",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#a0abb6",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#a0abb6",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#262626",
    "--cms-mango-text-color9": "#fff",
    "--cms-mango-text-color9-h5": "#007afe",
    "--cms-mango-text-color10": "#d0d0d0",
    "--cms-mango-background-color6": "#2a2c34",
    "--cms-mango-close-color": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#479afe",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#479afe",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango31": {
    "--cms-primary-color": "#50d028",
    "--cms-mango-primary-color": "#50d028",
    "--cms-mango-primary-opacity-color": "rgba(80,208,40,0.3)",
    "--cms-mango-primary-dark-color": "#29810e",
    "--cms-primary-background-color": "#000",
    "--cms-mango-background-color": "#000",
    "--cms-mango-background-color2": "#222",
    "--cms-mango-background-color3": "#212121",
    "--cms-mango-background-color4": "#303440",
    "--cms-mango-background-color5": "#494c5b",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#1d1d1d",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#000",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#50d028,#29810e)",
    "--cms-mango-user-progress-background-color": "var(--cms-mango-text-color4)",
    "--cms-mango-user-vip-background-color": "#fff",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#000",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#fff",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#a0abb6",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-color9": "#fff",
    "--cms-mango-text-color9-h5": "#007afe",
    "--cms-mango-text-color10": "#d0d0d0",
    "--cms-mango-background-color6": "#2a2c34",
    "--cms-mango-close-color": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#479afe",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#479afe",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango32": {
    "--cms-primary-color": "#6cc26d",
    "--cms-mango-primary-color": "#6cc26d",
    "--cms-mango-primary-opacity-color": "rgba(108,194,109,0.3)",
    "--cms-mango-primary-dark-color": "#4b9552",
    "--cms-mango-header-login-btn-color": "linear-gradient(#738595,#304657)",
    "--cms-mango-header-login-btn-hover-color": "linear-gradient(#3a495c,#192737)",
    "--cms-mango-header-register-btn-color": "linear-gradient(#6cc26d,#4b9552)",
    "--cms-mango-header-register-btn-hover-color": "linear-gradient(#1f6d3d,#14552f)",
    "--cms-mango-background-color": "#000",
    "--cms-mango-background-color-end": "#0f171f",
    "--cms-mango-background-color2": "#121932",
    "--cms-mango-background-color2-end": "#02081a",
    "--cms-mango-background-color3": "#101934",
    "--cms-mango-background-color4": "#354f60",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#243241",
    "--cms-mango-header-background-color": "#2a3941",
    "--cms-mango-personal-background-color": "linear-gradient(#243241,#5a6579,#243241)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#223141,#151c34)",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.1)",
    "--cms-mango-border-color": "#4c5551",
    "--cms-mango-border-light-color": "#b1bad3",
    "--cms-mango-border-dark-color": "#2d445b",
    "--cms-mango-text-color": "#aeb6cd",
    "--cms-mango-text-color2": "#5a6579",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#a5b0c9",
    "--cms-mango-text-color5": "#d4dbdb",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00b5b5",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "var(--cms-mango-background-color2-end)",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango4": {
    "--cms-primary-color": "#4c9dec",
    "--cms-mango-primary-color": "#4c9dec",
    "--cms-mango-primary-opacity-color": "rgba(142,109,195,0.3)",
    "--cms-mango-primary-dark-color": "#5a7092",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#fff",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#fff",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(180deg,#81daff,#226ab0)",
    "--cms-mango-user-progress-background-color": "#7dd4fd",
    "--cms-mango-user-vip-background-color": "#f5f5f5",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#2b2b2b",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#5b5c5f",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#ffba70",
    "--cms-mango-tip-dark-yellow-color": "#ffba70",
    "--cms-mango-tip-golden-color": "#ffba70",
    "--cms-mango-tip-light-golden-color": "#ffba70",
    "--cms-mango-tip-red-color": "#cf2f22",
    "--cms-mango-tip-green-color": "#4da743",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,#f5f5f5,var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00ae78",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff",
    "--cms-mango-downlaod-background": "#fff",
    "--cms-mango-downlaod-font-color": "var(--cms-mango-text-color7)"
  },
  "mango5": {
    "--cms-primary-color": "#ffe39a",
    "--cms-mango-primary-color": "#ffe39a",
    "--cms-mango-primary-opacity-color": "rgba(108,194,109,0.3)",
    "--cms-mango-primary-dark-color": "#ffb800",
    "--cms-mango-header-login-btn-color": "linear-gradient(#738595,#304657)",
    "--cms-mango-header-login-btn-hover-color": "linear-gradient(#3a495c,#192737)",
    "--cms-mango-header-register-btn-color": "linear-gradient(#6cc26d,#4b9552)",
    "--cms-mango-header-register-btn-hover-color": "linear-gradient(#1f6d3d,#14552f)",
    "--cms-mango-background-color": "#090b19",
    "--cms-mango-background-color-end": "#050610",
    "--cms-mango-background-color2": "#121932",
    "--cms-mango-background-color2-end": "#02081a",
    "--cms-mango-background-color3": "#151729",
    "--cms-mango-background-color4": "#354f60",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#141729",
    "--cms-mango-header-background-color": "#2a3941",
    "--cms-mango-personal-background-color": "linear-gradient(#243241,#5a6579,#243241)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#223141,#151c34)",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.1)",
    "--cms-mango-border-color": "#4c5551",
    "--cms-mango-border-light-color": "#b1bad3",
    "--cms-mango-border-dark-color": "#2d445b",
    "--cms-mango-text-color": "#aeb6cd",
    "--cms-mango-text-color2": "#5a6579",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#a5b0c9",
    "--cms-mango-text-color5": "#d4dbdb",
    "--cms-mango-text-color6": "#cbced8",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#2b2b2b",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,#00016b,var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00b5b5",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff"
  },
  "mango6": {
    "--cms-primary-color": "#035d03",
    "--cms-mango-primary-color": "#035d03",
    "--cms-mango-primary-opacity-color": "rgba(0,174,120,0.3)",
    "--cms-mango-primary-dark-color": "#4b9552",
    "--cms-primary-background-color": "#f5f5f5",
    "--cms-mango-background-color": "#f5f5f5",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#f5f5f5",
    "--cms-mango-background-color5": "#304654",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#035d03",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#3ee7d0,#346e46)",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ddd",
    "--cms-mango-text-color": "#000",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#2b2b2b",
    "--cms-mango-text-color4": "#5b5c5f",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#2b2b2b",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,#f5f5f5,var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00ae78",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff"
  },
  "mango7": {
    "--cms-primary-color": "#f81123",
    "--cms-mango-primary-color": "#f81123",
    "--cms-mango-primary-opacity-color": "rgba(0,174,120,0.3)",
    "--cms-mango-primary-dark-color": "#21464f",
    "--cms-mango-primary-dark-color2": "#f81123",
    "--cms-primary-background-color": "#ededed",
    "--cms-mango-background-color": "#ededed",
    "--cms-mango-background-color-end": "#ededed",
    "--cms-mango-background-color2": "#fff",
    "--cms-mango-background-color2-end": "#fff",
    "--cms-mango-background-color3": "#f5f5f5",
    "--cms-mango-background-color4": "#fff",
    "--cms-mango-background-color5": "#f81123",
    "--cms-mango-background-color6": "#000",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#fff",
    "--cms-mango-header-background-color": "#f81123",
    "--cms-mango-personal-background-color": "#fff",
    "--cms-mango-personal-item-background-color": "#f5f5f5",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-user-background-color": "linear-gradient(270deg,#3ee7d0,#346e46)",
    "--cms-mango-header-box-shadow-color": "18px 20px 20px 0 rgba(0,0,0,0.1)",
    "--cms-mango-tabbar-box-shadow-color": "0 0 10px 5px rgba(0,0,0,0.2)",
    "--cms-mango-border-color": "#ddd",
    "--cms-mango-border-light-color": "#f5f5f5",
    "--cms-mango-border-dark-color": "#ededed",
    "--cms-mango-border-dark-bold-color": "#fff",
    "--cms-mango-text-color": "#000",
    "--cms-mango-text-color2": "#5b5c5f",
    "--cms-mango-text-color3": "#000",
    "--cms-mango-text-color4": "#9b9ca1",
    "--cms-mango-text-color5": "#9b9ca1",
    "--cms-mango-text-color6": "#9b9ca1",
    "--cms-mango-text-color7": "#000",
    "--cms-mango-text-color8": "#000",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#80d647",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-game-rule-background-color": "\"#fff\"",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,#ededed,var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(360deg,#ff808a,#f81426)",
    "--cms-mango-agent-gradient-tab-color": "#f81123",
    "--cms-mango-agent-gradient-tab-bg-color": "#fff",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#ededed",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-mango-agent-icon-color1": "#f91e2f",
    "--cms-mango-agent-icon-color2": "#4551ff",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00ae78",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff"
  },
  "mango8": {
    "--cms-primary-color": "#f9bb22",
    "--cms-mango-primary-color": "#f9bb22",
    "--cms-mango-primary-opacity-color": "rgba(108,194,109,0.3)",
    "--cms-mango-primary-dark-color": "#e1a91d",
    "--cms-mango-header-login-btn-color": "linear-gradient(#738595,#304657)",
    "--cms-mango-header-login-btn-hover-color": "linear-gradient(#3a495c,#192737)",
    "--cms-mango-header-register-btn-color": "linear-gradient(#6cc26d,#4b9552)",
    "--cms-mango-header-register-btn-hover-color": "linear-gradient(#1f6d3d,#14552f)",
    "--cms-mango-background-color": "#30211c",
    "--cms-mango-background-color-end": "#1a110e",
    "--cms-mango-background-color2": "#210d06",
    "--cms-mango-background-color2-end": "#170703",
    "--cms-mango-background-color3": "#1a110e",
    "--cms-mango-background-color4": "#69534c",
    "--cms-mango-background-color5": "#69534c",
    "--cms-mango-mini-game-background-color": "linear-gradient(0deg,#354f60,#101934)",
    "--cms-mango-pop-background-color": "#30211c",
    "--cms-mango-header-background-color": "#2a3941",
    "--cms-mango-personal-background-color": "linear-gradient(#243241,#5a6579,#243241)",
    "--cms-mango-personal-item-background-color": "linear-gradient(180deg,#223141,#151c34)",
    "--cms-mango-progress-background-color": "#233242",
    "--cms-mango-header-box-shadow-color": "20px 22px 22px 0 rgba(0,0,0,0.1)",
    "--cms-mango-border-color": "#4c5551",
    "--cms-mango-border-light-color": "#b1bad3",
    "--cms-mango-border-dark-color": "#180e0a",
    "--cms-mango-text-color": "#c7aea5",
    "--cms-mango-text-color2": "#79625a",
    "--cms-mango-text-color3": "#fff",
    "--cms-mango-text-color4": "#c4aea6",
    "--cms-mango-text-color5": "#cec9c7",
    "--cms-mango-text-color6": "#d9d0cd",
    "--cms-mango-text-color7": "#fff",
    "--cms-mango-text-color8": "#fff",
    "--cms-mango-text-stroke-color": "#3e4c85",
    "--cms-mango-tip-grey-color": "#595e63",
    "--cms-mango-tip-yellow-color": "#f3de4c",
    "--cms-mango-tip-dark-yellow-color": "#ffaa17",
    "--cms-mango-tip-golden-color": "#ecb771",
    "--cms-mango-tip-light-golden-color": "#f1d499",
    "--cms-mango-tip-red-color": "#e73323",
    "--cms-mango-tip-green-color": "#2ddc4b",
    "--cms-mango-tip-blue-color": "#2281f6",
    "--cms-mango-download-gradient-bg-color": "radial-gradient(circle,var(--cms-mango-background-color),var(--cms-mango-background-color))",
    "--cms-mango-turntable-gradient-bg-color": "linear-gradient(270deg,#010958,#1108bf)",
    "--cms-mango-turntable-center-btn-color": "linear-gradient(180deg,#ffb629,#ff3d00)",
    "--cms-mango-turntable-award-rules-color": "linear-gradient(180deg,#003370,#111923 87%,#111923)",
    "--cms-mango-tabbar-gradient-bg-color": "linear-gradient(180deg,#5a91e9,var(--cms-mango-primary-color))",
    "--cms-mango-agent-background-color": "#1a2951",
    "--cms-mango-agent-background-color2": "#0d214b",
    "--cms-mango-agent-background-color3": "#262626",
    "--cms-mango-agent-background-color4": "#183470",
    "--cms-mango-agent-border-color": "#1d5ca7",
    "--cms-mango-agent-border-color2": "#284676",
    "--cms-mango-agent-fill-color": "#343946",
    "--cms-mango-agent-gradient-color": "#5a91e9",
    "--cms-mango-agent-gradient-color2": "#67ccc2",
    "--cms-mango-agent-text-green-color": "#094",
    "--cms-loading-text-color": "#fff",
    "--cms-mango-tips-background": "#00b5b5",
    "--cms-mango-tips-btn-background": "#21464f",
    "--cms-mango-tips-btn-text": "#fff"
  },
  "orange": {
    "--cms-primary-color": "#fa9d10",
    "--cms-primary-color-transition": "rgba(250,157,16,0.05)",
    "--cms-primary-color-disabled": "rgba(250,157,16,0.4)",
    "--cms-primary-nav-color-hover": "#fff100",
    "--cms-primary-color-hover": "#fff100",
    "--cms-primary-second-color": "#10e56f",
    "--cms-primary-background-color": "#fff",
    "--cms-default-btn-color": "linear-gradient(#dbffe9,#effff8,#f8fffc,#eefff9,#fff)",
    "--cms-active-btn-color": "linear-gradient(#73f9ae,#35cf79,var(--cms-primary-color))",
    "--cms-default-btn-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67),inset 0px 1px 7px 0px #fff,inset 0px -3px 4px 0px rgba(175,194,230,0.16)",
    "--cms-active-btn-shadow-color": "inset 0px 0px 3px 0px hsla(0,0%,100%,0.6),0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-primary-light-color": "rgba(250,157,16,0.7)",
    "--cms-primary-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-primary-active-border-color": "#abffd0",
    "--cms-login-register-bg-color": "rgba(250,157,16,0.85)",
    "--cms-primary-icon-bg": "#e1bd61",
    "--cms-primary-icon-bg-gradient": "linear-gradient(180deg,#ffc164,var(--cms-primary-color))",
    "--cms-primary-icon-bg-gradient-right": "linear-gradient(270deg,#81daff,var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-second-color) 1%,var(--cms-primary-color))",
    "--cms-loading-background-color": "rgba(0,0,0,0.5)",
    "--cms-loading-block-background-color": "rgba(0,0,0,0.3)",
    "--cms-loading-block-h5-background-color": "none",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#fff",
    "--cms-user-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-background-card-color": "#fff",
    "--cms-background-card--avtive-color": "rgba(250,157,16,0.1)",
    "--cms-user-menu-active-color": "rgba(250,157,16,0.1)",
    "--cms-user-info-color": "linear-gradient(180deg,#e1fff1,#fafafa)",
    "--cms-user-info-btn-shadow-color": "4px 4px 6px 1px rgba(26,118,85,0.2)",
    "--cms-login-bg-color": "rgba(0,153,68,0.85)",
    "--cms-login-btn-color": "#fff100",
    "--cms-banner-slide-color": "#a3d9bb",
    "--cms-title-color": "#000",
    "--cms-sub-title-color": "#666",
    "--cms-primary-background-row-color": "#f2f2f2",
    "--cms-text-tip-color": "#bb3537",
    "--cms-game-name-bg-color": "linear-gradient(180deg,rgba(250,157,16,0),#1e85ff)",
    "--cms-game-nav-bg-color": "linear-gradient(180deg,#ffc164,var(--cms-primary-color))",
    "--cms-game-nav-text-color": "#fa9d10",
    "--cms-game-left-popup-bg": "rgba(250,157,16,0.6)",
    "--cms-game-left-popup-bg2": "rgba(250,157,16,0.6)",
    "--cms-game-nav-text-active-color": "#fff",
    "--cms-game-scence3-bg": "#e3ebf8",
    "--cms-withdrawal-wallet-bg-color": "linear-gradient(270deg,#ffc164,var(--cms-primary-color))",
    "--cms-live-content-primary-background-color": "linear-gradient(180deg,#effff5,#effff8 24%,#f8fffc 55%,#eefff9 85%,#fff)",
    "--cms-live-content-primary-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-live-content-primary-border-color": "1px solid rgba(0,153,68,0.4)",
    "--cms-live-content-footer-primary-background-color": "linear-gradient(270deg,#097434,#effff8 19%,#139658 38%,#67c899 66%,#eefff9 85%,#087a36)"
  },
  "pink": {
    "--cms-primary-color": "#ee6e6d",
    "--cms-primary-color-transition": "rgba(238,110,109,0.05)",
    "--cms-primary-color-disabled": "rgba(238,110,109,0.4)",
    "--cms-primary-nav-color-hover": "#fff100",
    "--cms-primary-color-hover": "#fff100",
    "--cms-primary-second-color": "#10e56f",
    "--cms-primary-background-color": "#fff",
    "--cms-default-btn-color": "linear-gradient(#dbffe9,#effff8,#f8fffc,#eefff9,#fff)",
    "--cms-active-btn-color": "linear-gradient(#73f9ae,#35cf79,var(--cms-primary-color))",
    "--cms-default-btn-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67),inset 0px 1px 7px 0px #fff,inset 0px -3px 4px 0px rgba(175,194,230,0.16)",
    "--cms-active-btn-shadow-color": "inset 0px 0px 3px 0px hsla(0,0%,100%,0.6),0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-primary-light-color": "rgba(238,110,109,0.7)",
    "--cms-primary-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-primary-active-border-color": "#abffd0",
    "--cms-login-register-bg-color": "rgba(238,110,109,0.85)",
    "--cms-primary-icon-bg": "#e1bd61",
    "--cms-primary-icon-bg-gradient": "linear-gradient(180deg,#f4a1a0,var(--cms-primary-color))",
    "--cms-primary-icon-bg-gradient-right": "linear-gradient(270deg,#f4a1a0,var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-second-color) 1%,var(--cms-primary-color))",
    "--cms-loading-background-color": "rgba(0,0,0,0.5)",
    "--cms-loading-block-background-color": "rgba(0,0,0,0.3)",
    "--cms-loading-block-h5-background-color": "none",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#fff",
    "--cms-user-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-background-card-color": "#fff",
    "--cms-background-card--avtive-color": "rgba(238,110,109,0.1)",
    "--cms-user-menu-active-color": "rgba(238,110,109,0.1)",
    "--cms-game-scence3-bg": "#e3ebf8",
    "--cms-user-info-color": "linear-gradient(180deg,#e1fff1,#fafafa)",
    "--cms-user-info-btn-shadow-color": "4px 4px 6px 1px rgba(26,118,85,0.2)",
    "--cms-login-bg-color": "rgba(0,153,68,0.85)",
    "--cms-login-btn-color": "#fff100",
    "--cms-banner-slide-color": "#a3d9bb",
    "--cms-title-color": "#000",
    "--cms-sub-title-color": "#666",
    "--cms-primary-background-row-color": "#f2f2f2",
    "--cms-text-tip-color": "#bb3537",
    "--cms-game-name-bg-color": "linear-gradient(180deg,rgba(238,110,109,0),#1e85ff)",
    "--cms-game-nav-bg-color": "linear-gradient(180deg,#f4a1a0,#ee6e6d)",
    "--cms-game-nav-text-color": "#ee6e6d",
    "--cms-game-left-popup-bg": "rgba(238,110,109,0.6)",
    "--cms-game-left-popup-bg2": "rgba(238,110,109,0.6)",
    "--cms-game-nav-text-active-color": "#fff",
    "--cms-withdrawal-wallet-bg-color": "linear-gradient(180deg,#f4a1a0,#ee6e6d)",
    "--cms-live-content-primary-background-color": "linear-gradient(180deg,#effff5,#effff8 24%,#f8fffc 55%,#eefff9 85%,#fff)",
    "--cms-live-content-primary-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-live-content-primary-border-color": "1px solid rgba(0,153,68,0.4)",
    "--cms-live-content-footer-primary-background-color": "linear-gradient(270deg,#097434,#effff8 19%,#139658 38%,#67c899 66%,#eefff9 85%,#087a36)"
  },
  "purple": {
    "--cms-primary-color": "#1f002e",
    "--cms-primary-color-transition": "rgba(31,0,46,0.05)",
    "--cms-primary-color-disabled": "rgba(31,0,46,0.4)",
    "--cms-primary-nav-color-hover": "#fff100",
    "--cms-primary-color-hover": "#fff100",
    "--cms-primary-second-color": "#10e56f",
    "--cms-primary-background-color": "#fff",
    "--cms-default-btn-color": "linear-gradient(#dbffe9,#effff8,#f8fffc,#eefff9,#fff)",
    "--cms-active-btn-color": "linear-gradient(#73f9ae,#35cf79,var(--cms-primary-color))",
    "--cms-default-btn-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67),inset 0px 1px 7px 0px #fff,inset 0px -3px 4px 0px rgba(175,194,230,0.16)",
    "--cms-active-btn-shadow-color": "inset 0px 0px 3px 0px hsla(0,0%,100%,0.6),0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-primary-light-color": "rgba(31,0,46,0.7)",
    "--cms-primary-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-primary-active-border-color": "#abffd0",
    "--cms-login-register-bg-color": "rgba(31,0,46,0.85)",
    "--cms-primary-icon-bg": "#e1bd61",
    "--cms-primary-icon-bg-gradient": "linear-gradient(180deg,#622c7c,var(--cms-primary-color))",
    "--cms-primary-icon-bg-gradient-right": "linear-gradient(270deg,#81daff,var(--cms-primary-color))",
    "--cms-gradient-btn-color": "linear-gradient(90deg,var(--cms-primary-second-color) 1%,var(--cms-primary-color))",
    "--cms-loading-background-color": "rgba(0,0,0,0.5)",
    "--cms-loading-block-background-color": "rgba(0,0,0,0.3)",
    "--cms-loading-block-h5-background-color": "none",
    "--cms-loading-text-color": "#fff",
    "--cms-background-color": "#fff",
    "--cms-user-title-bg-color": "linear-gradient(270deg,#4ea38e,var(--cms-primary-color))",
    "--cms-background-card-color": "#fff",
    "--cms-background-card--avtive-color": "rgba(31,0,46,0.1)",
    "--cms-user-menu-active-color": "rgba(31,0,46,0.1)",
    "--cms-user-info-color": "linear-gradient(180deg,#e1fff1,#fafafa)",
    "--cms-user-info-btn-shadow-color": "4px 4px 6px 1px rgba(26,118,85,0.2)",
    "--cms-login-bg-color": "rgba(0,153,68,0.85)",
    "--cms-login-btn-color": "#fff100",
    "--cms-banner-slide-color": "#a3d9bb",
    "--cms-title-color": "#000",
    "--cms-sub-title-color": "#666",
    "--cms-primary-background-row-color": "#f2f2f2",
    "--cms-text-tip-color": "#bb3537",
    "--cms-game-name-bg-color": "linear-gradient(180deg,rgba(250,157,16,0),#1e85ff)",
    "--cms-game-nav-bg-color": "linear-gradient(180deg,#622c7c,#1f002e)",
    "--cms-game-nav-text-color": "#1f002e",
    "--cms-game-left-popup-bg": "rgba(31,0,46,0.6)",
    "--cms-game-left-popup-bg2": "rgba(31,0,46,0.6)",
    "--cms-game-nav-text-active-color": "#fff",
    "--cms-game-scence3-bg": "#f5edf9",
    "--cms-withdrawal-wallet-bg-color": "linear-gradient(180deg,#622c7c,#1f002e)",
    "--cms-live-content-primary-background-color": "linear-gradient(180deg,#effff5,#effff8 24%,#f8fffc 55%,#eefff9 85%,#fff)",
    "--cms-live-content-primary-shadow-color": "0px 4px 8px 0px rgba(194,222,202,0.67)",
    "--cms-live-content-primary-border-color": "1px solid rgba(0,153,68,0.4)",
    "--cms-live-content-footer-primary-background-color": "linear-gradient(270deg,#097434,#effff8 19%,#139658 38%,#67c899 66%,#eefff9 85%,#087a36)"
  }
};